
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useAuth } from '@/composables/useAuth'
import { useNotifications } from '@/composables/useNotifications'
export default defineComponent({
    name: 'ForgotPassword',
    setup() {
        const { resetPassword } = useAuth()
        const email = ref('')
        const router = useRouter()
        const { setNotification } = useNotifications()

        const submitForm = async () => {
            if (email.value === '') {
                setNotification('Uzupełnij email')
                return false
            }
            const result = await resetPassword(email.value)
            if (result) {
                router.push({ name: 'LoginForm' })
            }
        }

        return {
            router,
            email,
            submitForm,
        }
    },
})
